interface MissionProps {
    icon: React.ReactElement
    title: string;
    content: string;
  }
  export const Mission: React.FC<MissionProps> = ({
    icon,
    title,
    content,
  }) => {
    return (
      <div className="flex flex-col items-center">
        <div className="md:w-1/2 p-4">
          {icon}
        </div>
        <div className="md:w-1/2 p-4">
          <h2 className="text-2xl font-bold mb-4">
            <span className="text-1xl text-black">{title}</span>
          </h2>
          <p className="text-base text-start pt-2">{content}</p>
        </div>
      </div>
    );
  };