import React, { useState } from 'react';
import { footMenu, socialMenu } from './menu';
import axios from 'axios';

const Foot =() =>{
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const handleClick = async () => {
    if (email) {
      setLoading(true);
      setSuccessMessage('');
      try {
        const response = await axios.post('http://localhost:3001/subscribe', { email });
        console.log(response.data.message);
        setEmail(''); // Réinitialiser le champ d'email
      } catch (error) {
        console.error('Erreur lors de l\'inscription :', error);
        setSuccessMessage('Vous avez été ajouter à la newsletter !');
        setEmail(''); 
      } finally {
        setLoading(false);
      }
    } else {
      setSuccessMessage('');
      console.log('Veuillez entrer un email');
    }
  };
  return (
    <div className='grid grid-cols-1 lg:grid-cols-3 gap-4 w-full lg:w-[70%] justify-between lg:justify-center h-full py-8 lg:space-x-2'>
  <div className="flex flex-col items-center lg:items-start w-full">
    <span className="text-4xl text-white font-bold pb-6">HappyLivraison</span>
    <div className="flex flex-col pt-2 lg:pt-0 items-center lg:items-start">
      <span className="text-sm lg:text-base text-white font-light">Suivez-nous 👇🏿</span>
      <div className="flex flex-row pt-2 space-x-2">
        {socialMenu.map((menu: any) => (
          <a 
            target="_blank" 
            key={menu.path} 
            href={menu.path} 
            className="text-sm lg:text-base text-white font-normal hover:text-black"
          >
            {menu.element} 
          </a>
        ))}
      </div>
    </div>
  </div>

  <div className="flex flex-col items-center lg:items-start w-full">
    <span className="text-2xl text-white font-bold mt-4 pb-4">Pages utiles</span>
    {footMenu.map((menu: any) => (
      <a 
        key={menu.path} 
        href={menu.path} 
        className="text-sm lg:text-base text-white font-normal hover:text-black"
      >
        {menu.name} 
      </a>
    ))}
  </div>

  <div className="flex flex-col items-center lg:items-start w-full">
    <span className="text-base text-white font-bold">Inscrivez-vous</span>
    <div className="relative mt-2 rounded-md shadow-sm w-full">
      <input
        id="email"
        name="email"
        type="text"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Votre email"
        className="block w-full lg:h-[78px] rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
      />
      <button onClick={handleClick} className="absolute inset-y-0 right-0 flex items-center bg-black rounded-r-lg">
      {loading ? (
              <svg
                className="animate-spin h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8v8h8a8 8 0 11-8-8z"
                ></path>
              </svg>
            ) : (
              <span className="text-white sm:text-sm p-4">S'inscrire</span>
            )}
        {/* <span className="text-white sm:text-sm p-4">S'inscrire</span> */}
      </button>
    </div>
    {/* {successMessage && (
          <span className="text-sm text-white mt-2">{successMessage}</span>
        )} */}
  </div>
</div>


  );
}

export default Foot;