import { AccessibilityIcon, ClockIcon, DeliveryIcon, PanaIcon, PromoIcon, QuickIcon, RafikIcon, SatisfiedIcon, SmileIcon, TakeIcon } from "../assets";
import Banner from "../assets/about-banner-1.png"
import First from "../assets/about-banner.png"
import Media from "../assets/media.png"
import Media1 from "../assets/media (1).png"
import Media2 from "../assets/media (2).png"
import IService from "../assets/Rectangle 4.png"
import IService1 from "../assets/Rectangle 7.png"
import IService2 from "../assets/Rectangle 4 (1).png"
import IService3 from "../assets/Rectangle 7 (1).png"

export const Choices =[
    {
        icon: <PanaIcon width={"215"} height={"216"} color={""} />,
        title: "Demander une livraison",
        content: "Notre service vous contactera pour discuter de tous les détails de votre commande"
    },
    {
        icon: <TakeIcon width={"247"} height={"230"} color={""} />,
        title: "Recupération des colis",
        content: "la livraison de vos commandes par notre service à l'heure qui vous convient"
    },
    {
        icon: <RafikIcon width={"247"} height={"185"} color={""} />,
        title: "Le colis est livré",
        content: "votre commande est entre vos mains profitez  de ce que vous avez commandé"
    }
  ];

  export const PromoChoices = [
    {
      key: 'service-entreprise',
      iconColor: "#EA542B",
      title: "Services Aux entreprise",
      content: "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    },
    {
      key: 'service-boutiques',
      iconColor: "#FFFFFF",
      title: "Services au boutiques",
      content: "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    },
    {
      key: 'service-particuliers',
      iconColor: "#EA542B",
      title: "Services aux particuliers",
      content: "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    }
  ];

  export const KpiChoices =[
    {
        icon: <SmileIcon width={"65"} height={"65"} color={"#EA542B"} />,
        title: "1000",
        content: "Client satisfaits"
    },
    {
        icon: <DeliveryIcon width={"65"} height={"65"} color={"#FFFFFF"} />,
        title: "100",
        content: "Livraison par jour"
    },
    {
        icon: <ClockIcon width={"60"} height={"60"} color={"#EA542B"} />,
        title: "10",
        content: "Minutes de livraison"
    }
  ];

export const aboutSlides = [
    
    {
        image: First,
        alt: "about "
    },
    {
        image: Banner,
        alt: "about 2"
    },
  ];

  export const Missions =[
    {
        icon: <AccessibilityIcon width={"52"} height={"53"} color={""} />,
        title: "L’accessibilité à nos services",
        content: "En suivant la qualité de notre service, nous avons gagné"
    },
    {
        icon: <QuickIcon width={"52"} height={"53"} color={""} />,
        title: "Rapidité d’expéditions",
        content: "En suivant la qualité de notre service, nous avons gagné la confiance de nos nombreux clients."
    },
    {
        icon: <SatisfiedIcon width={"52"} height={"53"} color={""} />,
        title: "Satisfaction du client",
        content: "En suivant la qualité de notre service, nous avons gagné la confiance de nos nombreux clients."
    }
  ];


  export const Teams = [
    
    {
        image: Media,
        fullName: "Jacques MBAYE",
        job: "CEO"
    },
    {
        image: Media1,
        fullName: "Abdoulaye DIOP",
        job: "CTO"
    },
    {
        image: Media2,
        fullName: "John Hakenathon ",
        job: "CFO"
    },
  ];

  const para = "Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt, explicabo. Nemo enim ipsam voluptatem quia voluptassit." +
"Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt, explicabo. Nemo enim ipsam voluptatem quia voluptassit."
  
const OptionService = ["Découvrez Thalent Digital", "Découvrez Thalent Digital", "Découvrez Thalent Digital", "Découvrez Thalent Digital"]
  
  export const ServicesList = [
    {
      image: IService,
      title: "Livraison direct",
      content:para ,
      options: OptionService
    },
    {
        image: IService1,
        title: "Livraison à l’entrepot",
        content:para ,
        options: OptionService
      },
      {
        image: IService2,
        title: "Courrier",
        content:para ,
        options: OptionService
      },
      {
        image: IService3,
        title: "Livraison des restaurants",
        content:para ,
        options: OptionService
      }
  ];