import { FC } from "react";
import SliderComponent, { slide } from "./slider";

interface HeaderProps{
    image?: string
    animated?: boolean;
    slides: slide[] 
}

export const HeaderPage:FC<HeaderProps > = ({image, animated, slides})=> {
    return (
        <>
        {animated ? (
          <div className="slider-container">
            <SliderComponent slides={slides} />
          </div>
        ) : (
          <div className="image-container">
            <img src={image} alt="Static banner" />
          </div>
        )}
      </>
    );
  }
