import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import HRoutes from './routers';

function App() {
  return (
    <div className="App">
    <BrowserRouter>
    <HRoutes/>
    </BrowserRouter>
    </div>
  );
}

export default App;
