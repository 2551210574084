import { CheckCircleIcon } from "../../assets";

interface ServiceProps {
    image: string;
    title: string;
    content: string;
    options: string[];
    reverse?: boolean;
  }
  export const ServiceComponent: React.FC<ServiceProps> = ({
    image,
    title,
    content,
    options,
    reverse = false,
  }) => {
    return (
        <div className={`flex flex-col md:flex-row ${reverse ? 'md:flex-row-reverse' : ''} items-center py-4`}>
        <div className="md:w-1/2 p-4">
          <img src={image} alt={title} className="w-full h-auto" />
        </div>
        <div className="md:w-1/2 p-4">
          <h2 className="text-2xl font-bold mb-4 text-start">
            <span className="text-black ">{title}</span>
          </h2>
          <p className="text-base text-start">{content}</p>
          <ul className="mt-4">
          {options.map((option, index) => (
            <li key={index} className="flex items-center mt-2">
              <span className="mr-2">
                <CheckCircleIcon width={"24"} height={"24"} color={""}/>
              </span>
              {option}
            </li>
          ))}
        </ul>
        </div>
      </div>
    );
  };
  