import { HeaderPage } from "./component/header/page.header";
import { Description } from "./component/section/description";
import { aboutSlides, Missions, Teams } from "./data";
import Who from "./assets/who-we-are.png"
import { Mission } from "./component/section/mission";
import { Team } from "./component/section/team";
import { Testimonial } from "./component/section/testimonial";
import Avatar from "./assets/#user.1.jpg"

const testimonials = [
    {
      quote: "HappyLivraison m’a permis de pas tarder les commandes de mes clients.",
      avatar: Avatar,
      author: "Regina Miles",
      role: "Vendeur en Chine",
      rating: 4,
    }
  ];

  const Title= "Qui sommes nous?"
    const Content = "Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt, explicabo. Nemo enim ipsam voluptatem quia voluptassit."
+ "Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt, explicabo. Nemo enim ipsam voluptatem quia voluptassit."
   

export const About = ()=> {

     return (
      <div >
       <HeaderPage  animated slides={aboutSlides}/>
       <div className="pt-16">
        <Description image={Who} title={Title} content={Content}/>
       </div>
       <div className="pt-4">
       <h2 className="text-2xl font-bold mb-4">
          <span className="border-b-4 border-customOrange pb-2">Notre mission</span>
        </h2>
        <div className="flex flex-row flex-wrap py-12">
      {Missions.map((mission, index) => (
        <div key={index} className="w-full md:w-1/3 p-4">
          <Mission icon={mission.icon} title={mission.title} content={mission.content} />
        </div>
      ))}
    </div>
       </div>
       <div className="pt-4 items-center">
       <h2 className="text-2xl font-bold mb-4">
          <span className="border-b-4 border-customOrange pb-2">L’équipe fondatrice</span>
        </h2>
        <span className="text-center text-base text-[#737373]">Chaque membre de notre équipe partage un objectif commun : votre satisfaction totale à chaque livraison.</span>
        <div className="flex flex-row flex-wrap py-12 text-center  justify-center" style={{textAlign:"-webkit-match-parent"}}>
      {Teams.map((person, index) => (
        <div key={index} className="w-full md:w-1/3 p-4">
          <Team image={person.image} fullName={person.fullName} job={person.job} />
        </div>
      ))}
    </div>
       </div>
       <div className="flex flex-col items-center py-32 bg-customOrange/45">
      <h2 className="text-2xl font-bold mb-4">
        Ce que disent nos clients et partenaires
      </h2>
      {testimonials.map((testimonial, index) => (
        <Testimonial
              key={index}
              quote={testimonial.quote}
              author={testimonial.author}
              role={testimonial.role} 
              avatar={testimonial.avatar}
              rating={testimonial.rating}        />
      ))}
    </div>
      </div>
    );
}