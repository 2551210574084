import { About } from "../app/About";
import { Contact } from "../app/Contact";
import { Home } from "../app/Home";
import { Services } from "../app/Services";
import { HubPaths } from "./paths";

export const navigationPaths = [
  {
    path: HubPaths.Home,
    element: <Home />,
  },
  {
    path: HubPaths.Services,
    element: <Services/>,
  },
  {
    path: HubPaths.About,
    element: <About />,
  },
  {
    path: HubPaths.Contacts,
    element: <Contact />,
  }
];
