import { HubPaths } from "../../routers/paths";
import { FacebookIcon, FacebookOrangeIcon, InstagramIcon, InstagramOrangeIcon, TwitterIcon, TwitterOrangeIcon } from "../assets";

export const mainMenu = [
    {
      path: HubPaths.Home,
      name: "Accueil"
    },
    {
        path: HubPaths.Services,
        name: "Services"
      },
      {
        path: HubPaths.About,
        name: "A propos"
      },
      {
        path: HubPaths.Contacts,
        name: "Contacts"
      },
  ];

  export const footMenu = [
    {
        path: HubPaths.About,
        name: "A propos"
    },
    {
      path: HubPaths.Contacts,
      name: "Contacts"
    },
    // {
    //   path: HubPaths.Hiring,
    //   name: "Nous recrutons"
    // },
    {
        path: HubPaths.Services,
        name: "Services"
    },
  ];

  export const socialMenu = [
    {
      path: "https://www.facebook.com/profile.php?id=61562614179126&mibextid=LQQJ4d",
      element: <FacebookIcon width={'34'} height={'34'} color={'none'} />,
    },
    {
      path: "https://www.instagram.com/happylivraison_?igsh=aHlhMXhtbnN1aXI0&utm_source=qr",
      element: <InstagramIcon width={'34'} height={'34'} color={'none'} />,
    },
    {
      path: "https://www.twitter.com/",
      element: <TwitterIcon width={'34'} height={'34'} color={'white'} />,
    }
  ];

  export const socialTeam = [
    {
      path: "https://www.facebook.com/profile.php?id=61562614179126&mibextid=LQQJ4d",
      element: <FacebookOrangeIcon width={'24'} height={'24'} color={'none'} />,
    },
    {
      path: "https://www.instagram.com/happychiill?igsh=YXU3bWluOGYzZHNz",
      element: <InstagramOrangeIcon width={'24'} height={'24'} color={'none'} />,
    },
    {
      path: "https://www.twitter.com/",
      element: <TwitterOrangeIcon width={'24'} height={'24'} color={'none'} />,
    }
  ];
