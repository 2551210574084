import React from 'react';
import { PromoIcon } from '../../assets';

interface OnlineProps {
  title: string;
  content: string;
  containerClassName: string;
  iconClassName: string;
  textClassName: string;
  iconColor: string
}

export const Online: React.FC<OnlineProps> = ({

  title,
  content,
  containerClassName,
  iconClassName,
  textClassName,
  iconColor
}) => {
  return (
    <div className={`w-full flex flex-col items-center border shadow-xl shadow-gray-500/50 rounded-lg ${containerClassName}`}>
      <div className={`md:w-2/3 p-4 ${iconClassName}`}>
      <PromoIcon width={"113"} height={"97"} color={iconColor} />
      </div>
      <div className={`md:w-2/3 p-4 ${textClassName}`}>
        <h2 className="text-2xl text-start font-bold mb-4">
          <span className="text-1xl">{title}</span>
        </h2>
        <p className="text-base text-start pt-2">{content}</p>
      </div>
    </div>
  );
};
