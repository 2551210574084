interface KpiProps {
    icon: React.ReactElement
    title: string;
    content: string;
  }
  export const Kpi: React.FC<KpiProps> = ({
    icon,
    title,
    content,
  }) => {
    return (
        <div className="flex flex-col items-center p-4">
        <div className="flex items-center w-full justify-center mb-4">
          <div className="mr-2">{icon}</div>
          <h2 className="text-3xl font-bold text-black">{title}</h2>
        </div>
        <p className="text-xl text-center font-bold">{content}</p>
      </div>
    );
  };