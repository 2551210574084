import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export interface slide{
    image: string
    alt: string
}

interface SliderProps{
slides: slide[]
}
const SliderComponent: React.FC<SliderProps > = ({slides}) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <div className="slider-container">
      <Slider {...settings}>
      {slides.map((slide: any, index) => (
        <div key={index}>
          <img className="w-full h-35" src={slide.image} alt={slide.alt} />
        </div>
          ))}
      </Slider>
    </div>
  );
};

export default SliderComponent;
