import { HeaderPage } from "./component/header/page.header";
import Banner from "./assets/contact-banner.png";
import { socialMenu } from "./layout/menu";
import { Envelope, PhoneIcon, PositionIcon } from "./assets";
import { ContactForm } from "./Form";
import { Maps } from "./Maps";

export const Contact = () => {
  return (
    <div>
      <HeaderPage image={Banner} slides={[]} />
      <div className="flex flex-col lg:flex-row justify-between items-center space-y-8 px-4 lg:px-16">
        {/* Div pour le contact */}
        <div className="w-full lg:w-1/3 mb-8 lg:mb-0 relative lg:z-50 lg:mt-[-320px] lg:mb-auto">
          <div className="w-[80%] rounded-lg bg-customOrange p-4">
            <div className="text-2xl text-white items-center text-center font-bold py-4">
              Contactez-nous
            </div>
            <div className="flex flex-col items-start pl-8">
              <div className="flex items-center mb-4">
                <Envelope
                  width={"19"}
                  height={"10"}
                  color={"white"}
                  className="mr-2"
                />
                <span className="text-white text-base">
                  info@happylivraison.com
                </span>
              </div>
              <div className="flex items-center mb-4">
                <PhoneIcon
                  width={"13"}
                  height={"14"}
                  color={"white"}
                  className="mr-2"
                />
                <span className="text-white text-base">+221338643987</span>
              </div>
              <div className="flex items-center mb-4">
                <PositionIcon
                  width={"25"}
                  height={"25"}
                  color={"white"}
                  className="mr-2"
                />
                <span className="text-white text-base">
                  Almadies zone 4, Dakar, Senegal
                </span>
              </div>
            </div>
            <div className="text-base text-white items-center text-center py-4">
              Media sociaux
            </div>
            <div className="flex flex-col items-start py-4 pl-8">
              <div className="pt-4">
                {socialMenu.map((menu: any) => (
                  <div key={menu.path} className="flex items-center mb-4">
                    <a target="_blank" href={menu.path} className="mr-2">
                      {menu.element}
                    </a>
                    <span className="text-white text-base">
                      @happylivraison
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className="text-base text-white items-center text-center ">
              Heures d'ouverture
            </div>
            <div className="flex flex-col items-start py-4 pl-8">
              <span className="text-base text-white ">08H00 - 13H00</span>
              <span className="text-base text-white ">15H00 - 17H00</span>
            </div>
          </div>
        </div>

        {/* Div pour le formulaire de contact */}
        <div className="w-full lg:w-2/3">
          <div className="text-2xl text-customOrange text-start font-bold">
            Envoyez-nous un message
          </div>
          <div className="text-base text-black text-start py-4">
            Nous serions ravis d'avoir de vos nouvelles et de répondre à vos
            questions. Vous pouvez nous contacter en remplissant le formulaire
            ci-dessous, en nous envoyant un courriel ou en nous appelant à notre
            numéro de téléphone. Nous vous répondrons dans les plus brefs
            délais. Vous pouvez également nous suivre sur nos plateformes de
            médias sociaux et vous abonner à notre newsletter pour rester
            informé de nos dernières nouvelles et offres. Nous vous remercions
            de votre intérêt et de votre soutien.
          </div>
          <ContactForm />
        </div>
      </div>

      <div className="px-4 py-6">
        <Maps />
      </div>
    </div>
  );
};
