import { HeaderPage } from "./component/header/page.header";
import { ServiceComponent } from "./component/section/service";
import { aboutSlides, ServicesList } from "./data";

export const Services = ()=> {

    return (
     <div >
      <HeaderPage  animated slides={aboutSlides}/>
      <div className="pt-16 pb-32">
      <h2 className="text-2xl font-bold mb-4">
         <span className="border-b-4 border-customOrange pb-2">Nos services</span>
       </h2>
       <span className="text-center text-base text-[#737373] pt-4">Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremqueae ab illo inventore veritatis et </span>

      </div>
      <div className="flex flex-col items-center pb-8 px-4">
      {ServicesList.map((service, index) => (
        <ServiceComponent
          key={index}
          image={service.image}
          title={service.title}
          content={service.content}
          options={service.options}
          reverse={index % 2 !== 0} 
        />
      ))}
    </div>
     </div>
   );
}