import { useNavigate } from "react-router-dom";

interface HomeSection2Props {
    image: string;
    title: string;
    content: string;
    options: string[];
  }
  export const HomeSection2: React.FC<HomeSection2Props> = ({
    image,
    title,
    content,
    options
  }) => {

    const navigate = useNavigate();

    const handleContact = () => {
        navigate("/contact-us")
    }
    return (
      <div className="flex flex-col md:flex-row items-start pt-8">
        <div className="md:w-1/2 p-4">
          <img src={image} alt={title} className="w-full h-auto" />
        </div>
        <div className="md:w-1/2 p-8">
          <h2 className="text-3xl text-start font-bold mb-4">
            <span className="border-b-4 border-customOrange">{title}</span>
          </h2>
          <ul className="mt-4 flex flex-wrap">
          {options.map((option, index) => (
            <li key={index} className="flex items-center mt-2 mr-4">
              {index > 0 && <span className="mr-2">→</span>}
              <span className="text-customOrange text-base font-bold">{option}</span>
            </li>
          ))}
        </ul>
          <p className="text-base text-start py-6">{content}</p>

          <button onClick={handleContact} className="bg-customOrange w-full lg:w-[30%] px-3 py-2 text-white rounded-lg items-start"
          style={{float:"inline-start"}}>Nous contacter</button>
        </div>
      </div>
    );
  };
  