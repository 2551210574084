import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { HomeSection2 } from "./component/section/home.section";
import { Mission } from "./component/section/mission";
import { Choices, KpiChoices, PromoChoices } from "./data";
import { PromoSection } from "./component/section/promo";
import { Online } from "./component/section/online";
import { Kpi } from "./component/section/kpi";
import { HeaderHome } from "./component/header/home.header";
import Driver from "./assets/Group 55.png"
import { ArrowRightIcon } from "./assets";
import Google from "./assets/Google Play badge.png"
import Apple from "./assets/App Store badge.png"

const TitleSection = "Rapidité et Sourire à Chaque Livraison";
const StepSection = ["Inscription", "Nous contacter", "Livraison"]
const ContentSection = "Avec Happylivraison, recevez vos colis avec un sourire, en un temps record."

export const Home = ()=> {
    const navigate = useNavigate();
    const [hoveredIndex, setHoveredIndex] = useState<number |null>(null);
    const isLg = useMediaQuery({ minWidth: 1024 });

    const handleServices = () => {
        navigate("/services")
    }
    
    return (
      <div >
        <HeaderHome/>
       <div className="pt-4">
       <h2 className="text-3xl font-bold mb-4">
          <span className="border-b-4 border-customOrange pb-2">Pourquoi nous choisir ?</span>
        </h2>
        <span className="text-center text-base text-customOrange pt-6">nous nous efforçons de rendre notre service rapide et pratique pour vous</span>
        <div className="flex flex-row flex-wrap py-12 pt-8">
      {Choices.map((choice, index) => (
        <div key={index} className="w-full md:w-1/3 p-4">
          <Mission icon={choice.icon} title={choice.title} content={choice.content} />
        </div>
      ))}
    </div>
       </div>
       <div className="py-6">
        <HomeSection2 image={Driver} title={TitleSection} content={ContentSection} options={StepSection}/>
       </div>
       <div>
      <PromoSection
        videoUrl="/delivery-video.mp4" 
        // imageUrl={Promo} 
        title=" "
        description=" "
      />
    </div>
    <div className="pt-8 items-center">
       <h2 className="text-3xl font-bold mb-4">
          <div className="">Nos exceptionnels <span className="text-customOrange">Services en ligne</span> </div>
        </h2>
        <span className="text-center text-base text-black pt-6">Malesuada tellus tincidunt fringilla enim, </span>
        <div className="flex flex-row flex-wrap py-1 lg:py-12 pt-8">
      {PromoChoices.map((choice, index) => {
         const isHovered = hoveredIndex === index;
         const containerClassName = isHovered ? 'bg-customOrange' : 'bg-white';
         const iconClassName = isHovered ? 'text-white' : 'text-customOrange';
         const textClassName = isHovered ? 'text-white' : 'text-black';
         const iconColor = isHovered ? '#FFFFFF' : "#EA542B";

        return (
          <div key={index} className="w-full md:w-1/3 p-4"
          onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
             >
            <Online 
               iconColor={iconColor}
              title={choice.title} 
              content={choice.content} 
              containerClassName={containerClassName} 
              iconClassName={iconClassName} 
              textClassName={textClassName} 
            />
          </div>
        );
      })}
      
    </div>
    {isLg ? (
        <div className="flex items-center place-content-center">
        <button onClick={handleServices} className="text-white bg-customOrange px-3 py-2 w-full lg:w-[11%] rounded-lg">Voir plus</button>
        <ArrowRightIcon className=" mb-12 -ml-2 text-customOrange" width={"164"} height={"154"} color={""} />
      </div>
      ) : (
        <div className="flex flex-col items-center ">
          <ArrowRightIcon className="ml-6 -mb-[38px]" width={"164"} height={"154"} color={"#EA542B"} />
          <button onClick={handleServices} className="text-white bg-customOrange px-3 py-2 w-full rounded-lg">
            Voir plus
          </button>
        </div>
      )}
       </div>
       <div className="flex flex-row flex-wrap py-12 pt-8 bg-customOrange/20">
      {KpiChoices.map((choice, index) => (
        <div key={index} className="w-full md:w-1/3 p-4">
          <Kpi icon={choice.icon} title={choice.title} content={choice.content} />
        </div>
      ))}
    </div>
    <div className="pt-4">
       <div className="text-3xl pt-4 font-bold mb-12">
          HappyLivraison
        </div>
        <div className="text-center text-2xl text-customOrange font-bold pt-12">Découvrez une nouvelle ère de la livraison rapide et fiable avec Hapylivraison.</div>
       
        <div className="text-base text-black text-center py-12">Téléchagez l’application et obtenez 10% de reduction sur votre premier livraison</div>
        <div className="flex justify-center space-x-4 py-4">
        <a href="https://play.google.com/store" target="_blank" rel="noopener noreferrer">
          <img src={Google} alt="Google Play Store" className="w-40 h-[99%]" />
        </a>
        <a href="https://www.apple.com/app-store/" target="_blank" rel="noopener noreferrer">
          <img src={Apple} alt="Apple App Store" className="w-40 h-auto" />
        </a>
      </div>
       </div>
      </div>
    );
  }