import React from 'react';
import ReactPlayer from 'react-player';

interface PromoSectionProps {
  videoUrl?: string;
  imageUrl?: string;
  title: string;
  description: string;
}

export const PromoSection: React.FC<PromoSectionProps> = ({
  videoUrl,
  imageUrl,
  title,
  description,
}) => {
  return (
    <div className="relative w-full h-screen flex items-center justify-center text-center text-white overflow-hidden">
      {videoUrl ? (
        <video
          className="absolute top-0 left-0 w-full h-full object-cover"
          controls
          playsInline
          autoPlay
          muted
          loop
        >
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <div
          className="absolute top-0 left-0 w-full h-full bg-cover bg-center"
          style={{ backgroundImage: `url(${imageUrl})` }}
        />
      )}
      <div className="relative z-10 p-4">
        <h1 className="text-2xl md:text-6xl font-bold mb-4 text-customOrange">{title}</h1>
        <p className="text-lg md:text-2xl mb-8">{description}</p>
      </div>
      <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>
    </div>
  );
};
