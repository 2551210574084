import React, { useEffect } from "react";
import { Navbar } from "./navbar";
import { useNavigate } from "react-router-dom";
import Foot from "./footer";

export const AdminLayout = ({ children }: any) => {
  const navigate = useNavigate();
  


  return (
    <div className="w-screen min-h-screen h-screen flex justify-center font-ubuntu">
  {/* <div className="relative w-full h-full flex flex-col"> */}
      <div className="flex flex-col h-full w-full">
          <Navbar />
        <main className="w-full flex flex-col lg:flex-row ">
           <div className="w-full bg-white flex-grow "> 
            {children}
           </div> 
        </main>
        <div className="w-full flex flex-col justify-center items-center bg-customOrange py-3 mt-auto">
          <Foot />
          <span className="text-sm lg:text-base text-white font-light">
            Copyright © 2024 <a target="_blank" href="https://innovatelq.sn">Innovatelq</a>. All Rights Reserved.
          </span>
        </div>
      </div>
  {/* </div> */}
</div>





  );
};
