export const ContactForm = () => {
  return (
    <div className="w-full pt-8  space-y-8  flex flex-col items-center justify-center">
      <input
        type="tel"
        placeholder="Nom complet"
        //   value={phoneNumber}
        //   onChange={handleInputChange(setPhoneNumber)}
        className="placeholder:text-start text-left pl-6 focus:outline-none w-full lg:w-full py-4 lg:py-[25px] placeholder-customGray rounded-lg box-border h-8 border-2 text-xl font-extralight"
      />
      <input
        type="email"
        placeholder="Votre adresse e-mail"
        // value={fullName}
        // onChange={handleInputChange(setFullName)}
        className="placeholder:text-start text-left pl-6 focus:outline-none w-full lg:w-full py-4 lg:py-[25px] placeholder-customGray rounded-lg box-border h-8 border-2 text-xl font-extralight"
      />
      <input
        type="text"
        placeholder="Sujet"
        // value={fullName}
        // onChange={handleInputChange(setFullName)}
        className="placeholder:text-start text-left pl-6 focus:outline-none w-full lg:w-full py-4 lg:py-[25px] placeholder-customGray rounded-lg box-border h-8 border-2 text-xl font-extralight"
      />
      <textarea
        placeholder="Votre message ici 👇🏿..."
        // value={fullName}
        // onChange={handleInputChange(setFullName)}
        className="placeholder:text-start text-left pl-6 focus:outline-none w-full lg:w-full py-4 lg:py-[25px] placeholder-customGray rounded-lg box-border h-[100%] border-2 text-xl font-extralight"
      />
      <div className="items-start self-start">
        <button className="rounded-lg w-full px-6 py-4 bg-customOrange text-white ">
          Envoyer
        </button>
      </div>
    </div>
  );
};
