interface DescriptionProps {
  image: string;
  title: string;
  content: string;
}
export const Description: React.FC<DescriptionProps> = ({
  image,
  title,
  content,
}) => {
  return (
    <div className="flex flex-col md:flex-row items-center">
      <div className="md:w-1/2 p-4">
        <img src={image} alt={title} className="w-full h-auto" />
      </div>
      <div className="md:w-1/2 p-4">
        <h2 className="text-2xl font-bold mb-4">
          <span className="border-b-4 border-customOrange">{title}</span>
        </h2>
        <p className="text-base text-start">{content}</p>
      </div>
    </div>
  );
};
