import What from "../../assets/what.png"
import SmallImage from "../../assets/Frame 32.png"
import { useNavigate } from "react-router-dom"
export const HeaderHome= ()=> {
    const navigate = useNavigate();

    const handleContact = () => {
        navigate("/contact-us")
    }
return(
    <div className={`flex flex-col md:flex-row-reverse  items-center lg:py-4 mb-4`}>
        <div className="md:w-1/2 p-4 relative">
          <img src={What} alt="accueil" className="w-full h-auto" />
          <img
          src={SmallImage}
          alt="small"
          className="absolute top-0 left-0 w-16 h-16" 
        />
        </div>
        <div className="md:w-1/2 p-4 items-start">
          <h2 className="text-3xl font-bold mb-12 text-start">
            Pourquoi nous choisir ?
          </h2>
          <p className="text-xl text-start">
          nous nous efforçons de rendre notre service rapide et pratique pour vous
          </p>
          <div className="pt-8">
          <button onClick={handleContact} className="text-white bg-customOrange px-3 py-2 w-full lg:w-[21%] rounded-lg" style={{float:"inline-start"}}>Nous contacter</button>
          </div>
        </div>
      </div>
)
}