import React, { useState } from "react";
import Logo from "../assets/logo.png"
import { mainMenu } from "./menu";

export const Navbar = () => {
  const [activeMenu, setActiveMenu] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

// Fonction pour basculer l'état du menu
const toggleMenu = () => {
  setIsMenuOpen(!isMenuOpen);
};

  return (
        <>
        
        <nav className="fixed top-0 flex flex-row w-full h-[4rem] lg:h-[7rem] justify-between px-4 lg:px-16 bg-white z-50 border-b border-gray-300 shadow-xl">
        {/* Conteneur du logo et du burger menu */}
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center flex-shrink-0 text-white">
            <a href="/">
              <img
                src={Logo}
                alt="Logo"
                className="h-10 lg:h-16 lg:w-30"
              />
            </a>
          </div>

          {/* Burger menu pour petit écran */}
          <div className="lg:hidden">
            <button
              onClick={toggleMenu}
              className="block text-gray-500 hover:text-black focus:text-black focus:outline-none"
            >
              <svg
                className="h-6 w-6 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path
                  className="fill-current"
                  d="M4 6h16v2H4zm0 5h16v2H4zm0 5h16v2H4z"
                />
              </svg>
            </button>
          </div>
        </div>

        {/* Menu principal visible sur grand écran */}
        <div
          className={`hidden lg:flex w-full flex-row items-center justify-between ${
            isMenuOpen ? "fixed inset-0 bg-white z-50" : ""
          }`}
        >
          <div className="text-sm flex-grow">
            {mainMenu.map((menu: any) => (
              <a
                key={menu.path}
                href={menu.path}
                onClick={() => {
                  setActiveMenu(menu.path);
                  setIsMenuOpen(false); // Fermer le menu lorsqu'on clique sur un lien
                }}
                className="block mt-4 lg:inline-block lg:mt-0 text-black hover:text-customOrange text-2xl mr-4 font-bold"
              >
                {menu.name}
              </a>
            ))}
          </div>
        </div>

        {/* Popup du menu déroulant */}
        {isMenuOpen && (
          <>
            <div
              className="lg:hidden fixed inset-0 bg-black opacity-50 z-40"
              onClick={toggleMenu} // Fermer le menu en cliquant sur le fond obscurci
            ></div>
            <div className="lg:hidden absolute top-0 right-0 h-screen w-64 bg-white shadow-xl z-50">
              <div className="p-4">
                {mainMenu.map((menu: any) => (
                  <a
                    key={menu.path}
                    href={menu.path}
                    onClick={() => {
                      setActiveMenu(menu.path);
                      setIsMenuOpen(false); // Fermer le menu lorsqu'on clique sur un lien
                    }}
                    className="block mt-4 text-black hover:text-customOrange text-2xl font-bold"
                  >
                    {menu.name}
                  </a>
                ))}
              </div>
            </div>
          </>
        )}
      </nav>



      </>
  );
};
