import { socialTeam } from "../../layout/menu";

interface TeamProps {
    image: string
    fullName: string;
    job: string;
  }
  export const Team: React.FC<TeamProps> = ({
    image,
    fullName,
    job,
  }) => {
return(
    <div className="max-w-sm rounded overflow-hidden shadow-lg ">
      <img className="w-full" src={image} alt={fullName} />
      <div  className="lg:px-6 px-6 py-4 pt-4 w-full">
        <div className="flex flex-col justify-between">
        <span className="font-bold text-base sm:text-xl text-black uppercase">{fullName}</span>
        <span className="font-bold text-base text-black uppercase">{job}</span>
        <span className="flex flex-row pt-2 space-x-2 self-center">
        {socialTeam.map((social: any) => (
            <a 
            target="_blank" 
            key={social.path} 
            href={social.path} 
           className="text-sm lg:text-base text-white font-normal hover:text-black"
            >
            {social.element} 
          </a>
          ))}
        </span>
        </div>
      </div>
    </div>
)
  }