import { StarRating } from "../rating";

interface TestimonialProps {
    quote: string;
    avatar: string
    author: string;
    role: string;
    rating: number;
  }
  
  export const Testimonial: React.FC<TestimonialProps> = ({ quote,avatar, author, role, rating }) => {
    return (
        <div className="flex flex-col items-center p-4  ">
        <p className="text-lg text-center text-white font-bold py-4">“{quote}”</p>
        <img src={avatar} alt="" className="w-16 h-16 rounded-full" />
        <StarRating rating={rating} />
        <p className="mt-2 text-base text-center text-[#ECECEC] font-bold">{author}</p>
        <p className="text-sm text-white text-center">{role}</p>
      </div>
    );
  };